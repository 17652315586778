<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="소방설비유형 목록"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addInfo" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="POST"
            label="저장" 
            icon="save" 
            @beforeAction="saveInfo"
            @btnCallback="saveInfoCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <q-dialog v-model="prompt.visible" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">{{$label('코드')}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="prompt.code" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat :label="$label('취소')" @click="cancelCode" />
          <q-btn flat :label="$label('추가')" @click="setCode" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingType',
  data() {
    return {
      searchParam: {
        srchCodeGrpCd: 'SOP_FIRE_FIGHTING_TYPE_CD',
        srchUseFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            label: '코드',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'codeName',
            field: 'codeName',
            label: '소방설비유형',
            align: 'left',
            style: 'width:400px',
            sortable: true,
            required: true,
            type: 'text',
          },
          {
            name: 'description',
            field: 'description',
            label: '비고',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'number',
          },
          {
            name: 'attach',
            field: 'attach',
            label: '유형별 이미지',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'FIRE_FIGHTING_TYPE',
            keyText: 'code',
            sortable: false,
          },
        ],
        data: [],
      },
      prompt: {
        visible: false,
        code: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      validUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sys.code.mst.list.url;
      this.validUrl = selectConfig.sys.code.mst.valid.url;
      this.saveUrl = transactionConfig.sys.code.mst.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, this.searchParam.srchCodeGrpCd);
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addInfo() {
      this.prompt.visible = true;
      if (this.grid.data && this.grid.data.length > 0) {
        let _code = this.grid.data[this.grid.data.length - 1].code;
        if (_code.length == 10) {
          let _num = _code.replace(/[a-zA-Z]/g,'');
          let _eng = _code.replace(_num,'');
          this.prompt.code = _eng + String(parseInt(_num) + 1).padStart(_num.length,'0');
        }
      }
    },
    cancelCode() {
      Object.assign(this.$data.prompt, this.$options.data().prompt);
    },
    setCode() {
      this.$http.url = this.validUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        srchCodeGrpCd: this.searchParam.srchCodeGrpCd,
        srchCode: this.prompt.code
      };
      this.$http.request((_result) => {
        if (_result.data === 0) {
          let _order = 0;
          let cnt = this.grid.data.length;
          if (cnt == 0) {
            _order = 1;
          } else if (cnt == 1) {
            _order = 10;
          } else {
            _order = this.grid.data[cnt-1].sortOrder + 10;
          }
          if (!this.grid.data) this.grid.data = [];
          this.grid.data.splice(0, 0, {
            codeGrpCd: this.searchParam.srchCodeGrpCd,
            code: this.prompt.code,
            langs: 'kr',
            codeName: '',
            attrVal1: '',
            attrVal2: '',
            attrVal3: '',
            attrVal4: '',
            description: '',
            sortOrder: _order,
            useFlag: 'Y',
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            editFlag: 'C'
          })
          this.cancelCode();
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', // 
            message: '해당 코드는 이미 존재합니다.\n다른 코드를 작성해주세요.',
            type: 'error', // success / info / warning / error
          });
        }
      },);
    },
    saveInfo() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveInfoCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
